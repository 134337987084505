
      @import "variables.scss";
      @import "mixins.scss";
    
.vendorPriceContainer {
  margin-bottom: -12px;
}

.vendorPriceContent {
  margin-top: -12px;
}

.vendorPriceContent li {
  margin-top: 4px;
}
