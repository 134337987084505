
      @import "variables.scss";
      @import "mixins.scss";
    
.detailMap {
  margin-top: 12px;
  position: relative;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 400px;
  z-index: 1;
  cursor: pointer;
}

.detailMapSectionInfo {
  height: 512px;
}

.detailMapFullScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: var(--zindexVenueDetailMap);
  margin-top: 0px;
  cursor: pointer;
}
