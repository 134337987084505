
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.inquiryItem {
  transition: all 0.3s ease-out;

  cursor: pointer;
  padding: 24px 20px;
  border-bottom: 1px solid $grey300;
  background-color: $paper;
  border-right: 2px solid transparent;

  &.active {
    background-color: $grey200;
    border-right: 2px solid $secondaryMain;
  }

  .inquiryInfo {
    display: inline-block;

    .commonText {
      display: inline-block;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      color: $grey400;
    }

    .inquiryType {
      @extend .commonText;
    }

    .inquiryDescription {
      @extend .commonText;
    }
  }

  .eventNameWrapper {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;
    }
  }

  .inquiryTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: $darkPaper;
    margin: 3px 0;
  }

  .dataTime {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey500;
    margin-bottom: 10px;
  }

  .cardBottomWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .buttonsWrapper {
      display: inherit;
      justify-content: inherit;
    }
  }

  .amountDaysContainer {
    text-align: right;
  }

  .amountDaysPayment {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey500;
    margin-top: -3px;
    padding-left: 20px;
  }
}
