
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listOptionsItem {
  display: block;

  .listOptionsItemLabel {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey700;
  }
}
