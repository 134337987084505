
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.detailContainer {
  padding: 0 20px;
  overflow: hidden;

  &.withFAQ {
    padding: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $paper;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey300;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey400;
  }
}

.transition {
  transition: all ease-in-out 0.4s;
}

.detailInner {
  display: flex;
  position: relative;

  .detailInnerLeft {
    @extend .transition;

    width: calc(20% + 20px);
    position: absolute;
    z-index: 10;
    left: -20px;
    top: 0;
    height: 100%;
    background-color: $paper;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-left: 0;
    direction: rtl;

    .scrollingElement {
      direction: ltr;
      height: 100%;
    }

    &.detailInnerLeftClosed {
      width: 0;
      padding: 0;
      left: -20%;
    }

    &.detailInnerLeftCollapsed {
      padding-left: 20px;
      opacity: 0;

      &:hover {
        background-color: $paper;
        border-right: 1px solid $grey300;
        z-index: 99;
        opacity: 1;
        padding-left: 0;
      }
    }
  }

  .mainContainerInner {
    @extend .transition;

    width: 60%;
    max-height: calc(100vh - 128px);
    min-height: calc(100vh - 128px);
    padding: 30px 0 40px;
    position: relative;
    background-color: $paper;
    margin-left: 20%;
    border-right: 1px solid $grey300;
    border-left: 1px solid $grey300;
    z-index: 10;

    &.mainContainerFaqOpen {
      border: none;
      margin-left: 0;
      margin-right: 40%;
      border-right: none;
    }

    &.mainContainerInnerCollapsed {
      width: 80%;
      margin-left: 0px;
    }
  }

  .detailInnerRight {
    @extend .transition;

    width: calc(20% + 20px);
    height: 100%;
    padding: 0;
    position: absolute;
    overflow-y: auto;
    right: -20px;

    &.detailInnerRightClosed {
      right: -60%;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.drawerFAQContainer {
  @extend .transition;

  width: 40%;
  z-index: 1;
  position: absolute;
  overflow-y: auto;
  height: 100%;
  right: -45%;
  border-left: 1px solid $secondary50;

  &.drawerFAQContainerOpened {
    right: 0;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px;
}

.faqButton {
  width: 100%;
  background-color: $secondaryLight;
  padding: 10px 14px;
  color: $secondaryMain;
  text-transform: uppercase;
}

.closeRequestButton {
  @extend .faqButton;
  background-color: $error50;
  color: $errorMain;
}

.buttonExit {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999;
  padding: 12px;
}

@media (max-width: 1200px) {
  .detailInner {
    .mainContainerInner {
      padding: 20px 0 25px;
      font-size: 14px;

      &.mainContainerInnerRightCollapsed {
        width: 80%;
        margin-right: 0px;
      }

      &.mainContainerBothSidebarsCollapsed {
        width: 100%;
        margin: 0px;
      }
    }
  }

  .detailInnerRight {
    &.detailInnerRightCollapsed {
      padding-right: 20px;
      opacity: 0;

      &:hover {
        background-color: $paper;
        border-left: 1px solid $grey300;
        opacity: 1;
        padding-right: 20px;
        z-index: 999;
      }
    }
  }
}

@media (max-width: $breakpointMD) {
  .detailContainer {
    padding: 0;
  }

  .detailInner {
    margin: 0 -20px;

    .detailInnerLeft {
      position: static;
      width: 100%;
      height: auto;
      left: 0;
    }

    .mainContainerInner {
      max-height: unset;
      width: 100%;
      margin: 0;
      border: none;
      position: relative;
      padding: 20px 20px 25px;

      &.mainContainerFaqOpen {
        margin: 0;
      }

      &.mainContainerInnerCollapsed {
        width: 100%;
      }

      .switchButtonsList {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 9999;

        .switchButton {
          height: 40px;
          min-width: 40px;
          padding: 4px;
        }
      }
    }

    .detailInnerRight {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      position: static;
      right: 0;

      &.detailInnerRightClosed {
        right: 0;
      }
    }
  }
}

@media only screen and (min-width: $breakpointMD) and (max-width: $breakpointLG) {
  .buttonsContainer {
    margin: 10px;
  }
}
