
      @import "variables.scss";
      @import "mixins.scss";
    

.inactiveFallbackPopup {
  backdrop-filter: blur(5px);
}

@media (max-width: $breakpointMD) {
  .vendorAdjustSpacingMobile {
    margin-top: -24px;
  }
}
