
      @import "variables.scss";
      @import "mixins.scss";
    
.loadingSpinnerWrapper {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
