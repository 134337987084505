
      @import "variables.scss";
      @import "mixins.scss";
    
.container {
  margin: 0 auto;
  max-width: 1400px;
}

@media (min-width: $breakpointLG) {
  .container {
    box-shadow: 0px 20px 24px -4px rgba(69, 80, 100, 0.08),
    0px 8px 8px -4px rgba(69, 80, 100, 0.03);
  }
}
