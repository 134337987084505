
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.popupDialog {
  display: flex;
  justify-content: center;
  text-align: center;

  .closeBtnContainer {
    position: relative;
    z-index: 1;
  }

  .closeBtnContainerInner {
    position: absolute;
    top: -7px;
    right: 7px;
    color: $primary900;
    z-index: 999;
  }

  .iconWrapper {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }

  .popupTitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 0 35px;
    color: $primaryDark;
  }

  .popupSubTitle {
    font-size: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey800;
    margin-bottom: 20px;
  }

  .popupCTAButton {
    color: $paper;
    background-color: $secondaryMain;
    margin: 0 20px 15px;
  }
}

@media (max-width: $breakpointMD) {
  .popupDialog {
    .iconWrapper {
      padding: 10px 0;
    }
  }
}
