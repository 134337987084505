
      @import "variables.scss";
      @import "mixins.scss";
    
.bttnInner {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.bttnInner span {
  text-transform: lowercase;
}

.marginBottom {
  margin-bottom: 120px;
}

@media (max-width: $breakpointMD) {
  .marginBottom {
    margin-bottom: unset;
  }
}
