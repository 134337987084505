
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.faqWrapper {
  padding: 20px;
  font-size: 16px;
  position: relative;
  z-index: 1;
  background-color: $paper;
  border-left: 1px solid $secondary50;

  .buttonExit {
    position: absolute;
    z-index: 992;
    right: 0px;
    top: 0px;

    & :global .MuiIconButton-root {
      padding: 0;
    }
  }
}

@media (max-width: 1200px) {
  .faqWrapper {
    font-size: 14px;
  }
}
