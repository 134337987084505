
      @import "variables.scss";
      @import "mixins.scss";
    
.eventList {
  height: calc(100% - 170px);
  overflow: auto;

  &.fullHeight {
    height: 100%;
    padding-bottom: 85px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
