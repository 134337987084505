
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.panelTitle {
  font-size: 12px;
  color: $grey500;
  letter-spacing: 0.24px;
}

.accordionSummary {
  padding: 0 20px;
}

.expandIcon {
  font-size: 16px;
}

.rightSidebarAccordion {
  &:global(.Mui-expanded:before) {
    content: none;
  }

  &:global(.MuiAccordion-root:before) {
    content: none;
  }
}

@media only screen and (min-width: $breakpointMD) and (max-width: $breakpointLG) {
  .accordionSummary {
    padding: 0 10px;
  }
}

@media (max-width: $breakpointMD) {
  .accordionSummary {
    padding: 0 16px;
  }
}
