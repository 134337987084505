
      @import "variables.scss";
      @import "mixins.scss";
    
.venueThingsYouShouldKnowList {
  margin: 0;
}

.venueThingsYouShouldKnowList > li {
  margin-top: 4px;
}
