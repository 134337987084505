
      @import "variables.scss";
      @import "mixins.scss";
    
.venueLabelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  overflow: hidden;
}
