
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.paginationWrapper {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid $grey300;
}
