
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.switchExclusive {
  & :global .MuiSwitch-switchBase.Mui-checked {
    color: $secondary500;
    &:hover {
      background-color: alpha($secondary500);
    }
  }

  & :global .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: $secondary500;
  }
}

.exclusiveTag {
  color: $dark300;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
