
      @import "variables.scss";
      @import "mixins.scss";
    
.detailSpecButtonBar {
  display: flex;
  margin-top: 4px;
  gap: 6px;
}

.detailSpecButtonBar button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  width: 160px;
}

@media (max-width: $breakpointMD) {
  .detailSpecButtonBar button {
    flex-direction: column;
    gap: 4px;
    width: 100%;
    font-size: 12px;
  }
}
