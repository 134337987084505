
      @import "variables.scss";
      @import "mixins.scss";
    
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 213px;
  overflow-y: scroll;
}

.bookingBackground {
  width: 100%;
  height: 100%;
}
