
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.dataPickerWrapper {
  display: flex;
  gap: 8px;

  .filterDataPicker {
    cursor: pointer;

    & > label {
      background-color: $secondary100 !important;
    }
    & > label,
    & > div {
      font-size: 10px !important;
      color: $grey600 !important;
    }

    & > input {
      font-size: 10px !important;
      color: $grey600 !important;
    }
  }
}

@media (max-width: $breakpointLG) {
  .dataPickerWrapper {
    flex-direction: column;

    .filterDataPicker {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
