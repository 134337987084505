
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.headerContainer {
  border-radius: 2px;
  background-color: $secondary100;
  display: flex;
  gap: 8px;
  margin: 12px 40px 8px;
  padding: 16px;
}

.headerTitle {
  color: $dark300;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
}

.headerReason {
  color: $grey800;
  font-size: 12px;
  line-height: 16px;
}

.headerCTA {
  display: flex;
  margin-top: 16px;
  color: $dark300;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@media (max-width: $breakpointMD) {
  .headerContainer {
    margin: 0;
  }
}