
      @import "variables.scss";
      @import "mixins.scss";
    
.sendInquiryWrapper {
  padding-top: 8px;
  display: flex;
  justify-content: center;
}

.sendInquiryWrapper button {
  box-shadow: none !important;
}
