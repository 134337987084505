
      @import "variables.scss";
      @import "mixins.scss";
    
.container {
  min-height: 300px;
  margin: 0px auto;
}

.progressWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.containerLoading {
  padding-top: 260px;
}

.container h1 {
  padding-bottom: 12px;
}

.container h4 {
  margin-bottom: 12px;
}

@media (max-width: $breakpointMD) {
  .containerLoading {
    padding-top: 80px;
  }
}
