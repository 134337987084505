
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.detailTabsContainer {
  position: sticky;
  top: 80px;
  height: 70px;
  z-index: var(--zIndexDetailsTabsBarDesktop);
  background-color: var(--common-white);
  overflow-anchor: none;
}

.detailTabsContainerDesktopBg {
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 152px;
  top: 0px;
  background-color: var(--common-white);
  z-index: var(--zIndexDetailsTabsBarDesktop);
  overflow-anchor: none;
}

.detailTab {
  margin-top: -8px;
  //min-height: calc(100vh - 160px);
}

.detailsZIndexOff {
  z-index: 1 !important;
}

.inactiveFallbackPopup {
  backdrop-filter: blur(5px);
}

@media (max-width: $breakpointMD) {
  .detailTabsContainer {
    position: sticky;
    top: 0px;
    height: 80px;
    margin: 0px -24px;
    padding: 0px 24px;
    z-index: var(--zIndexDetailsTabsBarMobile);
  }

  .detailTabsContainerDesktopBg {
    display: none;
    position: unset;
    margin-left: unset;
    width: unset;
    height: unset;
  }

  .detailTabsContainer button {
    padding-left: 1px !important;
    padding-right: 1px;
    margin-right: 12px;
  }

  .detailSpecButtonBar button {
    flex-direction: column;
    gap: 4px;
    width: 100%;
    font-size: 12px;
  }
}

@media (max-width: $breakpointSM) {
  .detailTabsContainer {
    position: sticky;
    top: 0px;
    height: 80px;
    margin: 0px -12px;
    padding: 0px 12px;
    z-index: var(--zIndexDetailsTabsBarMobile);
  }
}
