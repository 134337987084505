
      @import "variables.scss";
      @import "mixins.scss";
    
.capacityContainer {
  display: flex;
  flex-direction: column;
}

.capacityGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 20px;
  width: 100%;
}

.capacityGridItem,
.capacityGridItemLeft {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.capacityGridItemIcon {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpointSM) {
  .capacityGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .capacityGridItem {
    justify-content: space-between;
    padding-right: 8px;
  }

  .capacityGridItemIcon {
    width: 60px;
  }

  .capacityGridItemCapacity span {
    font-weight: 600;
  }
}
