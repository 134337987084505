
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

/**
 * Listing Grid
 */

.listingHeaderBgImageContainer {
  position: absolute;
  top: -80px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 160px;
  z-index: -1;
  overflow: hidden;
}

.listingHeaderBgImage {
  width: 100%;
  height: 160px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.listingHeaderBgImageOverlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.26);
}

/**
 * Listing Filters / Tags
 */

.listingFilters {
  width: var(--listingFilterWidth) !important;
  border-right: 1px solid var(--grey-200);
  max-height: 2300px;
  overflow-y: scroll;
}

.listingFilters::-webkit-scrollbar {
  display: none;
}

.listFiltersTitle {
  display: none;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  gap: 4px;
  padding: 12px 12px 12px;
  border-bottom: 1px solid var(--grey-300);
}

/**
 * Listing Grid
 */

.listingContainer {
  --listingFilterWidth: 230px;
  padding: 0;
  display: flex;
  min-height: inherit;
  width: 100%;

  &:global(.map) {
    overflow-y: scroll;
    height: calc(100vh - 160px);
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  & :global(.loader) {
    display: flex;
    padding-bottom: 40px;
    flex-direction: column;
    height: calc(100vh - 160px);
    align-items: center;
    justify-content: center;
  }
}


/**
 * Tags for filters / search
 */
.listingGridTagsRow {
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px 0px 17px;
  gap: 12px;
}

.listingGridTagsRow:global(.map) {
  overflow-x: scroll;
  margin-bottom: 8px;
}

.listingGridTagsRow:global(.map)::-webkit-scrollbar {
  display: none;
}

.listingGridTagActual {
  text-transform: capitalize;
}

.listingMap {
  display: none;
}

.listingMap:global(.map) {
  display: block;
  width: 100%;
  max-width: 40%;
  min-width: 40%;
}

/**
 * Pagination
 */

.listingPaginationOption {
  text-decoration: none !important;
}

@media (max-width: $breakpointXL) {
  .listingMap:global(.map) {
    display: block;
    width: 40%;
    max-width: 40%;
  }

  .listingFilters {
    max-height: 3100px;
  }
}

@media (max-width: $breakpointLG) {
  .listingFilters {
    max-height: 4100px;
  }
}

@media (max-width: $breakpointMD) {
  .listingFilters {
    display: none;
  }

  .listingMap:global(.map) {
    width: 100%;
    max-width: 100%;
  }

  .listingGridTagsRow {
    flex-wrap: wrap;
  }
}
