
      @import "variables.scss";
      @import "mixins.scss";
    
.venueRoom section:last-child {
  border-bottom: none !important;
}

.venueRoomItem {
  --imageHeight: 360px;
}

.venueRoomItemImageContainer {
  height: var(--imageHeight);
}

.venueRoomItemImage {
  width: 100%;
  height: var(--imageHeight);
  cursor: pointer;
}

.venueRoomItemImageContainer > div {
  height: inherit !important;
}

.venueRoomItemImageContainer > div > div:first-child {
  height: inherit !important;
}

.venueRoomItemAbout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
}

.venueRoomItemPricing {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.venueRoomItemAddInquiryBttn {
  display: none;
  margin-top: 12px;
}

@media (max-width: $breakpointMD) {
  .venueRoomItemPricing {
    padding-bottom: 4px;
  }

  .venueRoomItemPricingBttn {
    display: none;
  }

  .venueRoomItemAddInquiryBttn {
    display: block;
  }
}
