
      @import "variables.scss";
      @import "mixins.scss";
    
.commonWrapperStyles {
  display: flex;
  width: 100%;
  height: 100vh;
}

.progressWrapper {
  @extend .commonWrapperStyles;

  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpointMD) {
  .commonWrapperStyles {
    height: 80vh;
  }
}
