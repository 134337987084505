
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.invoiceTabContent {
  padding: 20px 40px;
}

@media (max-width: $breakpointMD) {
  .invoiceTabContent {
    padding: 20px;
  }
}
