
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.commonWrapperStyles {
  display: flex;
  width: 100%;
  height: 100vh;
}

.errorWrapper {
  @extend .commonWrapperStyles;

  flex-direction: column;
  align-items: center;
  padding: 150px 20px 0;

  .errorText {
    margin-bottom: 15px;
    font-size: 12px;
    color: $errorMain;
  }
}

@media (max-width: $breakpointMD) {
  .commonWrapperStyles {
    height: 80vh;
  }
}
